<template>
    <login-layout>
        <h5 class="text-primary mb-5">Login</h5>
        <btn v-if="!isSuperUser" class="mb-4 text-left" text="Public" @click="userSelectClicked(false)" size="block"
             icon="fa fa-user-o"/>
        <btn v-else color="" class="mb-4 bs-5 text-left bgc-secondary-200" text="Public" @click="userSelectClicked(false)" size="block"
             icon="fa fa-user-o"/>
        <btn v-if="isSuperUser" class="mb-4 text-left" text="Admin" @click="userSelectClicked(true)" size="block"
             icon="fa fa-user-circle-o"/>
        <btn v-else color="" class="mb-4 bs-5 text-left bgc-secondary-200" text="Admin" @click="userSelectClicked(true)" size="block"
             icon="fa fa-user-circle-o"/>
        <div class="text-center mt-4">
            <btn class="pl-5 pr-5" text="Confirm" size="sm"/>
        </div>
    </login-layout>
</template>

<script>
import LoginLayout from './LoginPageLayout';

export default {
    name: 'LoginCheck',
    components: { LoginLayout },
    data () {
        return {
            isSuperUser: false
        };
    },
    methods: {
        userSelectClicked (value) {
            this.isSuperUser = value;
        },
        getBgColor () {
            if (this.isSuperUser === false) {
                const data = { background: '#0462c6', color: 'white' };
                return data;
            }
            const data = {};
            return data;
        },
        getBgColor1 () {
            if (this.isSuperUser === true) {
                const data = { background: '#0462c6', color: 'white' };
                return data;
            }
            const data = {};
            return data;
        }
    }
};
</script>
